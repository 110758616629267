import { FlexboxGrid } from 'rsuite'
import useTranslation from 'next-translate/useTranslation'

import SafeTranslate from '../common/SafeTranslate'
import MainCategoriesCarousel from './MainCategoriesCarousel/MainCategoriesCarousel'
import { useAuth } from '../../services/useAuth'
import useIsOnMobile from '../../services/useIsOnMobile'

import styles from '../../styles/HeroSectionV2.module.less'

const HeroSectionV2 = () => {
  const { t } = useTranslation('landing')
  const { user } = useAuth()
  const isOnMobile = useIsOnMobile()

  return (
    <FlexboxGrid
      as="section"
      className={styles['hero-section-container']}
      justify="start"
      align="middle"
    >
      <div className={styles.content}>
        <h1>
          <SafeTranslate
            i18nKey={user ? 'landing:Great to see you back, {{ name }}' : 'landing:Everything you need to get the job done'}
            components={{
              span: <span className="highlight-text-primary" />,
              br: !isOnMobile ? <br /> : <></>,
            }}
            values={{ name: user?.firstname || '' }}
          />
        </h1>
        <p>
          {user
            ? t('landing:Stick with what\'s worked before, or upgrade for the season, either way, we\'ve got you')
            : t('landing:Get the best prices for all the products you need with our real-time discount Because tight boots and supplier phone calls shouldn\'t be part of your day')}
        </p>
      </div>

      <MainCategoriesCarousel
        hideArrowsFade
        hideBrowseLink
        className={styles['features-banner-carousel']}
        cardClassName={styles['features-banner-carousel-card']}
      />

    </FlexboxGrid>
  )
}

export default HeroSectionV2
